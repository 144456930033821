import Vue from "vue";
import VueRouter from "vue-router";

import ChefsChoice from "../views/ChefsChoice.vue";
import Family from "../views/Family.vue";
import Handbook from "../views/Handbook.vue";
import Home from "../views/Home.vue";
import Landing from "../views/Landing.vue";
import Lunch from "../views/Lunch.vue";
import Menu from "../views/Menu.vue";

Vue.use(VueRouter);

const beforeEnter = validIds => (to, _, next) => {
  if (validIds.includes(to.params.id)) {
    next();
  } else {
    next("/");
  }
};

const routes = [
  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/",
    name: "landing",
    component: Landing
  },
  {
    path: "/handbok",
    name: "handbok",
    component: Handbook
  },
  {
    path: "/:id",
    name: "home",
    component: Home,
    beforeEnter: beforeEnter(["malmo", "stockholm"])
  },
  {
    path: "/:id/meny",
    name: "menu",
    component: Menu,
    beforeEnter: (to, from, next) => {
      const allowedIds = ["malmo"];
      if (allowedIds.includes(to.params.id)) {
        if (to.params.id === "malmo") {
          to.matched[0].components.default = Menu;
        }
        // } else if (to.params.id === "stockholm") {
        //   to.matched[0].components.default = MenuSthlm;
        // }
        next();
      } else {
        next("/");
      }
    }
  },
  {
    path: "/:id/lunch",
    name: "lunch",
    component: Lunch,
    beforeEnter: beforeEnter(["malmo"])
  },
  {
    path: "/:id/family-style",
    name: "family-style",
    component: Family,
    beforeEnter: beforeEnter(["malmo", "stockholm"])
  },
  {
    path: "/:id/chefs-choice",
    name: "chefs-choice",
    component: ChefsChoice,
    beforeEnter: beforeEnter(["malmo"])
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, _, next) => {
  const body = document.body;
  const validIds = ["malmo", "stockholm"];

  validIds.forEach(id => body.classList.remove(id));

  if (validIds.includes(to.params.id)) {
    body.classList.add(to.params.id);
  }

  next();
});

export default router;

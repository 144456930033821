<template>
  <article id="intro" class="d-grid intro">
    <section>
      <p class="accent mb-2">{{ data.name }}</p>
      <p class="mb-4">
        Lu &mdash; gatumat från Hong Kong.
        <br />Smakerna är kryddat och kantonesiskt. <br />Stämningen är som en
        kinesisk släktträff. Välkommen in i det ångande heta kaotiska köket.
        <span v-if="!data.bookings"
          ><br /><span class="italic">Vi har endast drop in</span>. Varmt
          välkomna!</span
        >
      </p>
      <div v-if="data.bookings">
        <h4 class="mb-0">Boka bord</h4>
        <p class="mb-2">
          Vi tar bokningar måndag&nbsp;-&nbsp;söndag från 17:00. Under lunchen
          har vi drop in. För sällskap 6 personer uppåt gäller
          <router-link to="/chefs-choice">Chef's Choice</router-link> för hela
          sällskapet.
        </p>
        <button
          class="waiteraid-widget btn btn-accent btn-ghost mb-2"
          data-hash="0c636f0fa4c925bd35094571b4d4b560"
        >
          Boka här
        </button>
      </div>
      <ul class="navigation mb-3">
        <li v-for="(link, index) in data.navigation" :key="`link-${index}`">
          <router-link :to="link.route">{{ link.label }}</router-link>
        </li>
      </ul>

      <table class="opening-hours mb-2">
        <tr
          v-for="(openingHour, index) in data.openingHours"
          :key="`hour-${index}`"
        >
          <td>{{ openingHour.days }}</td>
          <td>
            <ul class="mt-0 mb-0 p-0" v-if="openingHour.hours.length">
              <li v-for="(hour, i) in openingHour.hours" :key="`${i}`">
                {{ hour }}
              </li>
            </ul>
            <span v-else>Stängt</span>
          </td>
        </tr>
      </table>

      <p>
        {{ data.contact.adress }}
        <br />{{ data.contact.postal }} {{ data.contact.city }}
        <br />
        <a v-if="data.contact.phone" :href="phoneLink">
          {{ data.contact.phone }}
        </a>
      </p>
      <ul class="navigation">
        <li v-for="(link, index) in data.links" :key="`link-${index}`">
          <a :href="link.href">{{ link.label }}</a>
        </li>
      </ul>

      <p class="note">
        Vi är en kontantfri restaurang.
      </p>
    </section>
  </article>
</template>

<script>
const locations = {
  malmo: {
    bookings: true,
    navigation: [
      { route: "/malmo/family-style", label: "Family Style-meny" },
      { route: "/malmo/meny", label: "Take away-meny" },
      { route: "/malmo/lunch", label: "Lunch" },
      { route: "/malmo/chefs-choice", label: "Chef's choice" }
    ],
    openingHours: [
      { days: "Mån-Fre", hours: ["11:30-14:30", "17:00-22:00"] },
      { days: "Lördag", hours: ["13:00-22:00"] },
      { days: "Söndag", hours: [] }
    ],
    contact: {
      adress: "Davidshallstorg 3",
      postal: "211 45",
      city: "Malmö",
      phone: "040 — 31 98 98"
    },
    links: [
      { href: "https://www.instagram.com/lu.malmo", label: "Instagram" },
      { href: "mailto:hej@koket.lu", label: "Mail" }
    ],
    name: "Malmö Davidshallstorg"
  },
  stockholm: {
    bookings: false,
    navigation: [
      { route: "/stockholm/family-style", label: "Family Style-meny" }
      // { route: "/stockholm/meny", label: "Take away-meny" },
    ],
    openingHours: [
      { days: "Mån-Fre", hours: ["16:00-22:00"] },
      { days: "Lördag", hours: ["13:00-22:00"] },
      { days: "Söndag", hours: [] }
    ],
    contact: {
      adress: "Skånegatan 88",
      postal: "116 37",
      city: "Stockholm"
    },
    links: [
      { href: "https://www.instagram.com/lu.stockholm", label: "Instagram" },
      { href: "mailto:nytorget@koket.lu", label: "Mail" }
    ],
    name: "Stockholm Nytorget"
  }
};

export default {
  data: () => ({
    id: null,
    data: null
  }),
  created() {
    this.id = this.$route.params.id;
    this.data = locations[this.id] ?? null;
  },
  computed: {
    phoneLink() {
      if (this.data === null) {
        return;
      }
      return `tel:${this.data.contact.phone
        .replace(/[^\w\s]|_/g, "")
        .replace(/\s+/g, "")}`;
    },
    location() {
      return this.data.name;
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  &:first-of-type {
    margin-bottom: 40px;
  }
}

ul {
  list-style: none;
}

.navigation {
  padding: 0;
  margin-bottom: 20px;
  list-style: none;

  li {
    margin-bottom: 5px;
  }
}

.opening-hours {
  display: flex;
  flex-direction: column;

  tr {
    display: flex;

    td:first-child {
      width: 100px;
    }
  }
}

.note {
  font-size: 0.857rem;
}

.new-year {
  border-bottom: dotted 2px var(--accent-color);
  padding-bottom: 20px;
  margin-bottom: 20px;

  p {
    margin-bottom: 0px;
  }
}
</style>
